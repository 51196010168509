import React from 'react'

const defaultImage = "seg50.png"

// maps the segment type variable value 603/703/803/903 to the image name
const segmentImageByType = {
  21: "seg50.png",
  22: "seg50.png",
  23: "seg53.png",
  24: "seg54.png",
  25: "seg55.png",
  26: "seg56.png",
  27: "seg56.png",
  28: "seg256.png",
  29: "seg256.png",
  30: "seg66.png",
  31: "seg266.png",
  32: "seg85.png",
  33: "seg85.png",
  34: "seg285.png",
  35: "seg285.png",
  36: "seg86.png",
  37: "seg86.png",
  38: "seg286.png",
  39: "seg286.png",
  51: "seg50.png",
  52: "seg53.png",
  53: "seg54.png",
  54: "seg55.png",
  55: "seg56.png",
  56: "seg256.png",
  57: "seg66.png",
  58: "seg266.png",
  59: "seg85.png",
  60: "seg285.png",
  61: "seg86.png",
  62: "seg286.png",
  63: "seg08-110.png",
  64: "seg09-110.png",
  65: "seg385.png",
}

export const ScraperImage = ({ segmentType }) => {
    console.log("segmentImageByType", segmentImageByType[segmentType])
    let scraperImage = segmentType ? `/assets/img/scrapers/${segmentImageByType[segmentType]}` : `/assets/img/scrapers/${defaultImage}`
    
    if (scraperImage == null || scraperImage == undefined) {
      // when segmentType is present but it does not exist an image for it => render default image
      scraperImage = `/assets/img/scrapers/${defaultImage}`
    }

    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <img style={{maxWidth: "60vw", height: "300px"}} src={scraperImage} />
        </div>
    );
}

export default ScraperImage