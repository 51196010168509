import React, { useState, useEffect, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Radio from '@material-ui/core/Radio'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import theme from '../../../theme'
import Loader from '../../../components/Ux/Loader'
import { CUSTOMER_CONTROL_GET_CONTROL_REQUEST, CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST, CUSTOMER_CONTROL_SCRAPER_REQUEST } from '../../../store/customerControl/customerControlActions'
import Sidebars from '../../../components/layouts/Sidebars'
import useSetParentCustomInnerHeaderHeading from '../../../components/hooks/useSetParentCustomInnerHeaderHeading'
import ContentTitle from '../../../components/content-titles/ContentTitle'
import ContentInfoTitle from '../../../components/content-titles/ContentInfoTitle'
import { Grid } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  table: {
    [theme.breakpoints.up('lg')]: {
      paddingBottom: '45px'
    }
  },
  addButton: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    width: '100%'
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.dark,
    color: '#fff'
  },
  actions: {
    marginTop: '0.625rem',
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      marginRight: '0.625rem'
    },
    width: '100%'
  }
}))

function roundToTwoDecimals(value) {
  let num = parseFloat(value);
  return num.toFixed(2);
}

const ScraperMeasurements = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const [openDialog, setOpenDialog] = useState(false)
  const { customer } = useSelector((state) => state.customer)
  const [measurementId, setMeasurementId] = useState(null)
  const [NewMeasurements, setNewMeasurements] = useState([])
  const [openRoleChangeDialog, setOpenRoleChangeDialog] = useState(false)
  const [updateRoleValues, setUpdateRoleValues] = useState({})
  const isMount = useRef(false)

  const controlId = params.id
  const scraperNr = params.scraper_nr
  const { control, currentScraper, error, loading } = useSelector((state) => state.customerControl)
  const scraperIds = control && control.scraper_ids || {}
  const scraperId = scraperIds[scraperNr]
  const { segmentMeasurements, updateSuccess } = currentScraper || {}

  // Get control (FM) with scraper ids
  useEffect(() => {
    controlId && dispatch({ type: CUSTOMER_CONTROL_GET_CONTROL_REQUEST, payload: controlId })
  }, [dispatch, controlId])

  useEffect(() => {
    scraperId && dispatch({ type: CUSTOMER_CONTROL_SCRAPER_REQUEST, payload: scraperId })
  }, [dispatch, scraperId])

  // Handling error and displaying if comes during api call.
  useEffect(() => {
    if (isMount.current && error) {
      setMeasurementId(null)
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    } else {
      isMount.current = true
    }
  }, [error])

  // TODO: show toast when measurement is deleted
  // useEffect(() => {
  //   if (deletedId && measurementId) {
  //     setMeasurementId(null);
  //     dispatch({
  //       type: CUSTOMER_USER_LIST_REQUEST,
  //       payload: { id: params.id, data: { per_page: perPage, page: 1 } }
  //     });
  //     toast.success(t('list_scraper_measurements.toast.measurement_deleted'), {
  //       position: 'top-right',
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: false,
  //       progress: undefined
  //     });
  //   }
  // }, [deletedId])

  // TODO: confirm delete measurement
  // const handleConfirmDeleteUser = (e) => {
  //   setOpenDialog(false);
  //   if (e.currentTarget.value === 'true') {
  //     dispatch({
  //       type: CUSTOMER_USER_DELETE_REQUEST,
  //       payload: { id: measurementId }
  //     });
  //   } else {
  //     setMeasurementId(null);
  //   }
  // };

  const handleDeleteMeasurement = (id) => {
    // # TODO: maybe confirm this
    dispatch({
      type: CUSTOMER_CONTROL_UPDATE_SCRAPER_REQUEST,
      payload: { id: currentScraper.id, scraper: { segmentMeasurementsAttributes: [{ id, _destroy: true }] } }
    })
  }

  useEffect(() => {
    if (updateSuccess) {
      toast.success(t('scraper_measurement_form.toast.update_success'), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }
  }, [updateSuccess])

  // TODO: pagination
  // const onPageChange = (e, selectedPage) => {
  //   selectedPage = Number(selectedPage);
  //   let payload = { per_page: perPage, page: selectedPage }
  //   dispatch({
  //     type: CUSTOMER_USER_LIST_REQUEST,
  //     payload: {
  //       id: params.id,
  //       data: {
  //         ...payload
  //       }
  //     }
  //   });
  // }

  // navigates to edit user
  const editMeasurement = (measurement) => () => history.push(`./${measurement.id}/edit`)

  return (
    <>
      <Card className={classes.root + ' CardBorderStyle'}>
        <CardContent>
          <Loader open={loading} />
          <ContentTitle title={t('list_scraper_measurements.subtitle', { controlName: control && control.name, scraperNr })} />
          {/* <div>{t('list_scraper_measurements.subtitle', { controlName: "FM 123", scraperNr: "1" })}</div> */}
          {/* <ContentInfoTitle type={t('list_scraper_measurements.subtitle', { controlName: "FM 123", scraperNr: "1" })} name={customer && customer.name} no={customer && customer.customer_nr} /> */}
          <Grid container alignContent='space-between' className={classes.table + ' MainContInnerStyleTwo list-user'}>
            <Grid container direction='row' justifyContent='space-between' alignItems='stretch' className='RowDataHead pr-1-5 pl-1-5'>
              <Grid item xs={1} style={{ textAlign: 'left', paddingLeft: '1em', minWidth: 80 }}>
                <strong>h1 [mm]</strong>
              </Grid>

              <Grid item xs={1} style={{ textAlign: 'left', paddingLeft: '1em', minWidth: 80 }}>
                <strong>h2 [mm]</strong>
              </Grid>

              <Grid item xs={1} style={{ textAlign: 'left', paddingLeft: '1em', minWidth: 80 }}>
                <strong>&Delta;h [mm]</strong>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'left', paddingLeft: '1em', minWidth: 80 }}>
                <strong>&Delta;t [h]</strong>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'left', paddingLeft: '1em', minWidth: 80 }}>
                <strong>&Delta;V [mm³]</strong>
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'left', paddingLeft: '1em', minWidth: 80 }}>
                <strong>V%</strong>
              </Grid>

              <Grid item xs={1} className='TextCenter cancel-icon-col' />
            </Grid>

            {(segmentMeasurements && segmentMeasurements.length > 0)
              ? (<Grid item xs='12' className='Inner_scrollTwo' style={{ paddingBottom: '30px' }}>
                <div className='Inner_scroll-con pl-1 pr-1 pt-0'>
                  {segmentMeasurements.map((measurement, i) => (
                    <Grid
                      container key={measurement.id} direction='row' justifyContent='space-between'
                      alignItems='stretch' className='RowData TokenUpRow list-user-Area'
                    >
                      <Grid item xs={1} className='TextLeft  WordWrap'>
                        {roundToTwoDecimals(measurement.height1)}
                      </Grid>
                      <Grid item xs={1} className='TextLeft  WordWrap'>
                        {roundToTwoDecimals(measurement.height2)}
                      </Grid>
                      <Grid item xs={1} className='TextLeft  WordWrap'>
                        {measurement.deltaHeight}
                      </Grid>
                      <Grid item xs={1} className='TextLeft  WordWrap'>
                        {measurement.operatingHours}
                      </Grid>
                      <Grid item xs={1} className='TextLeft  WordWrap'>
                        {measurement.volumeCmm}
                      </Grid>
                      <Grid item xs={1} className='TextLeft  WordWrap'>
                        {measurement.volumePct}
                      </Grid>

                      <Grid item xs={1} className='TextCenter cancel-icon cancel-icon-col TextLeft dflex align-items-center justify-content-center '>
                        <img onClick={() => handleDeleteMeasurement(measurement.id)} src='/assets/img/cancel.png' style={{ cursor: 'pointer' }} />
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </Grid>) : null}

          </Grid>
          {!segmentMeasurements || !segmentMeasurements.length && (
            <div
              style={{
                padding: '1rem',
                textAlign: 'center',
                height: '100%',
                position: 'absolute',
                width: '100%',
                top: '0',
                right: '0',
                display: 'flex',
                alignContent: 'center',
                fontSize: '20px',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {t('noRecordsFound')}
            </div>
          )}
        </CardContent>
        <CardActions className='pl-1 pr-1 ButtonPanelWrap ButtonPanelWrap-2' style={{ flexDirection: 'column', alignItems: 'center' }}>
          <Box className={classes.addButton + ' ButtonPanel pl-1 pr-1'}>

            <Button
              style={{ marginRight: '0.625rem' }}
              onClick={() => history.push('./measurements/add/')}
              type='button'
              variant='outlined'
            >
              <img src='/assets/img/plus.png' />&nbsp;&nbsp;
              {t('list_scraper_measurements.newMeasurement')}
            </Button>
          </Box>
        </CardActions>
      </Card>
    </>
  )
}

export default Sidebars(ScraperMeasurements, { showSearch: false })
